export default {
  getTimesheets: state => (dataSlotName) => {
    return state.timesheets[dataSlotName] || [];
  },

  getTimesheetsMeta: state => {
    return state.timesheetsMeta
  },

  getTimesheet: state => {
    return state.timesheet;
  },

  getFiltersState: state => (filtersName) => {
    return state.filtersState[filtersName]
  },
  
  getCheckinsOverlay: state => (status) => {
    return state.checkinsOverlay[status]
  },
  

  getFilters: state => {
    return state.filters || [];
  },

  getDateSiteIds: state => {
    return state.dateSiteIds || [];
  },

  getError: state => {
    return state.error;
  },
  
  isTimesheetsPending: state => {
    return state.isTimesheetsPending
  },
};
