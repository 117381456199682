import { EventBus } from '~/plugins/event-bus';
import {disableLoader, enableLoader} from "@/helpers/loader";

export default {
  async init({ dispatch, state }) {
    if (!state.hasData) {
      dispatch('fetch');
    }
  },

  async fetch({ commit, state }, payload) {

    try {

      const response = await this.$axios.get('/api/me/timesheets', {
        params: payload.query,
      })
    
      const { data, meta } = response['data']

      commit('setTimesheets', {
        timesheets: data,
        replace: payload.reset,
        dataSlotName: payload.dataSlotName
      })

      commit('setTimesheetsMeta', meta)

      return data

    } catch (err) {
      commit('setTimesheetsMeta', err);
    }

  },

  async fetchTimesheetsPending({ commit, state }) {
    try {
      const pendingStatus = await this.$axios.get('/api/me/timesheets/pending');
      commit('setPendingTimesheets', pendingStatus.data.hasPending);
    } catch (err) {
    }
  },

  async fetchDetails({ commit, state }, {id, clearExisting}) {
    if (clearExisting) {
      commit('setTimesheet', null);
    }
    try {
      const timesheet = await this.$axios.get(`/api/me/timesheets/${id}`);
      commit('setTimesheet', timesheet.data.data);
    } catch (err) {}
  },

  async save({ commit, state }, payload) {
    enableLoader()
    const { id, entries, message } = payload;
    try {
      const timesheet = await this.$axios.put(`/api/me/timesheets/${id}`, {
        entries: entries,
        message: {
          type: 'text',
          content: message,
        },
      });

      EventBus.$emit('createSnackbar', {
        message: `Timesheet has been saved successfully.`,
      });

      const responseData = timesheet.data.data;
      if (responseData) {
        commit('setTimesheet', responseData);
      }

      disableLoader()
    } catch (err) {
      disableLoader()
    }
  },

  async setTimesheetBonus({ commit }, {id, bonus}) {
    try {
      const timesheet = await this.$axios.put(`/api/me/timesheets/${id}/bonus`, {bonus});
      EventBus.$emit('createSnackbar', {
        message: `Timesheet updated.`,
      });

      return timesheet.data.data;
    } catch (err) {}
  },

  async markAsPaid({ commit, dispatch}, payload) {
    enableLoader()
    const { id } = payload;
    try {
      const timesheet = await this.$axios.put(`/api/me/timesheets/${id}/paid`);
      EventBus.$emit('createSnackbar', {
        message: `Timesheet has been marked as Paid successfully.`,
      });

      commit('setTimesheet', timesheet.data.data);
      dispatch('fetchTimesheetsPending')
    } catch (err) {}
    disableLoader()
  },

  async timesheetAction({ commit, dispatch }, payload) {
    enableLoader()
    const { id, type } = payload;
    try {
      const timesheet = await this.$axios.put(
        `/api/me/timesheets/${id}/${type}`
      );
      let msg;
      if (type === 'accept') {
        msg = 'Timesheet has been accepted.';
      }
      if (type === 'reject') {
        msg = 'Timesheet has been rejected.';
      }
      if (type === 'submit') {
        msg = 'Timesheet has been submitted for review.';
      }

      EventBus.$emit('createSnackbar', {
        message: msg,
      });

      if (timesheet?.data?.data) {
        commit('setTimesheet', timesheet.data.data);
        dispatch('fetchTimesheetsPending')
      }
    } catch (err) {
      console.log(err);
    }
    disableLoader()
  },

  async sendNote({ commit }, data) {
    const { conversationId, content } = data
    try {
      const response = await this.$axios.post(
        `/api/me/timesheets/${conversationId}/message`,
        {
          message: {
            type: 'text',
            content,
          },
        }
      )

      EventBus.$emit('createSnackbar', {
        message: 'Note added successfully.',
      })

      return response.data

    } catch (err) {
      console.error(err);
      return err;
    }
  },

  async fetchFilters({ commit }, workerId = null) {
    try {
      const filters = await this.$axios.get('/api/me/timesheets/filters');
      commit('setFilters', filters.data);
    } catch (err) {
    }
  },
  
  async fetchSiteIdsForDate({ commit }, date) {
    try {
      const response = await this.$axios.get('/api/me/timesheets/site-ids-for-date', {params:{date}})
      commit('setDateSiteIds', response.data)
    } catch (err) {
    }
  },
  
  async fetchMoreFilters({ commit }, dateAnchor = null) {
    try {
      const filters = await this.$axios.get('/api/me/timesheets/filters', {
        params: {
          date: dateAnchor
        }
      })
      commit('setMoreFilters', filters.data)
      return filters.data
    } catch (err) {
      return null
    }
  },

  async checkinsOverlay({ commit }, {date, status}) {
    try {
      const response = await this.$axios.get('/api/me/timesheets/checkins-for-date', {params: {date, status}})
      const checkins = response.data?.data ?? []
      commit('setCheckinsOverlay', {checkins, status})
      return checkins;
    } catch (err) {
      console.log(err)
    }
  },
};
