import moment from 'moment';

export default {
  methods: {
    handleDataUpdate(data, form) {
      Object.entries(form).forEach(item => {
        const [key, valmap] = item;
        if (valmap === null) {
          return
        }

        let _empty = null;
        if (Array.isArray(form[key]?.['value'])) {
          _empty = [];
        }

        let val =
          valmap['key']?.split('.').reduce((o, i) => (o ? o[i] : null), data) ||
          _empty;

        if ('UiCheckbox' === valmap['selector'] && null === val) {
          // we assume no value for checkbox is teh same as unchecked or false
          val = 0;
        }

        if ('UiDatepicker' !== valmap['selector'] && null === val) {
          // for every non Datepicker fields with null values (not empty arras as for select fields)
          // we set it as empty string
          val = '';
        }

        if (form[key].hasOwnProperty('files')) {
          form[key]['files'] = [val];
        } else if (Array.isArray(form[key]['value']) && !Array.isArray(val)) {
          form[key]['value'] = JSON.parse(val);
        } else if (
          'UiSelect' === valmap['selector'] &&
          !isNaN(val) &&
          val.hasOwnProperty('length') &&
          val.length > 0
        ) {
          form[key]['value'] = parseInt(val);
        } else if (valmap['mask']) {
          form[key]['value'] = val.split('-').reverse().join('/');
        } else {
          form[key]['value'] = val;
        }
      });

      if (data && data.hasOwnProperty('dictionary')) {
        Object.keys(data.dictionary).forEach(key => {
          if (form.hasOwnProperty(key)) {
            const values = data.dictionary[key].map(v => v.id);
            form[key].value = form[key].options.filter(
              v => -1 !== values.indexOf(v.id)
            );
          }
        });
      }

      if (data && data.hasOwnProperty('dictMapIds')) {
        Object.keys(data.dictMapIds).forEach(key => {
          if (form[key] && form[key].options) {
            const value = data.dictMapIds[key].map(id => {
              return form[key].options.find(option => {
                return option.id === id;
              });
            });

            if (value) {
              form[key].value = value;
            }
          }
        });
      }
    },

    handleErrorInfo(data, form, isLinked = false, index) {

      this.clearErrorInfo(form);
      let errors = {};
      if (data.response && data.response.data && data.response.data.errors) {
        if (isLinked) {
          for(let key in data.response.data.errors) {
            if (key.includes(index)){
              errors[key.replace(`${index}.`, '')] = [data.response.data.errors[key][0].replace(`${index}.`, '')]
            }
          }
        } else {
          errors = data.response.data.errors;
        }

        errors && Object.entries(form).forEach(item => {
            const [key, field] = item;
            if ('UiFileupload' === form[key]['selector'] && form[key]['multiple']) {
              form[key]['errors'] = (errors[key] || []).reduce((acc, val) => {
                acc[val.mediaId] = val.message;

                return acc;
              }, []);
            } else {
              form[key]['errors'] = (errors[key] || [])
                .reduce((acc, val) => {
                  if ('object' === typeof val && val.hasOwnProperty('message')) {
                    acc.push(val.message);
                  } else {
                    acc.push(val);
                  }
                  return acc;
                }, []).join(', ');
            }

            form[key]['invalid'] = !!form[key]['errors'];
          });
      } else {
        console.log(data);
      }
      if (isLinked) {
        return form;
      }
    },

    clearErrorInfo(form) {
      Object.keys(form).forEach(key => {
        form[key].errors = null;
        form[key].invalid = false;
      });
    },

    clearForm(form) {
      const fields = Object.keys(form);

      fields.forEach(field => {
        form[field].value = null;
      });
    },

    getDataFromForm(form) {
      return Object.keys(form).reduce((prev, key) => {
        if (form[key].hasOwnProperty('files')) {
          if (form[key].multiple) {
            prev[key] = form[key].files.map(file => file['id']);
          } else {
            if (form[key].files.length) {
              prev[key] = form[key].files[0]['id'];
            } else {
              prev[key] = null;
            }
          }
        } else if (form[key].hasOwnProperty('value')) {
          prev[key] = form[key].value;
        } else {
          // fallback for unhandled fields
          prev[key] = null;
        }

        if (Array.isArray(prev[key])) {
          prev[key] = prev[key].map(el => el.id);
        } else if (prev[key] instanceof Date) {
          prev[key] = moment(prev[key]).format('YYYY-MM-DD');
        } else if (key === 'date_of_birth') {
          prev[key] = prev[key].split('/').reverse().join('-');
        }

        return prev;
      }, {});
    },

    handleAjaxForm(formData, dispatchName, redirectUrl, payload = {}) {
      return this.$store.dispatch(
        dispatchName,
        Object.assign(payload, { formData, redirectUrl })
      );
    },

    handleBack(url) {
      this.$router.go(-1)
    },

    goStepBackOwner(path) {
      if (path) {
        this.$router.push('/register/owner/' + path);
      } else {
        this.$router.push('register/owner/');
      }
    },
  },
};
